import React, { useEffect, useState } from "react";
import Layout from "../layouts/index";
import SEO from "../components/SEO";
import LazyIframe from "../components/LazyIframe";
import HomepageImage from "../images/homepage-img.png";
import Link from "gatsby-link";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import MailChimpBanner from "../components/Mailchimp-banner";
import PromoBanner from "../components/PromoBanner";
import LHTBanner from "../components/LHTBanner";
import applePodcast from "../images/icons/apple_podcast.svg";
import googlePodcast from "../images/icons/google_podcast.png";
import dollar from "../images/icons/dollar.svg";
import chat from "../images/icons/chat.svg";
import spotify from "../images/icons/spotify.svg";
const axios = require("axios").default;

const iconStyle = {
  width: "20px",
  marginRight: "8px",
  marginTop: "3px",
  marginBottom: "3px",
};

const podcastButton = {
  fontSize: "0.8em",
  backgroundColor: "white",
  borderRadius: "2px",
};

const mainButton = {
  padding: "12px 17px",
};

function PodcastPage() {
  const [episodes, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      // code to run on component mount
      const fetchData = async () => {
        setIsLoading(true);
        const accessToken = (
          await axios({
            url: "https://accounts.spotify.com/api/token",
            method: "post",
            headers: {
              Authorization:
                "Basic ZDU4MTU2YjkzYWJiNDIzNmJmZWEwZGNlNDcyOWJlYzM6NjU0YTMyZTdkNGMzNDUxMDg5YTlhNWQ1NGZhMTJmMmM=",
            },
            data: "grant_type=client_credentials",
          })
        ).data.access_token;

        if (accessToken) {
          const result = (
            await axios({
              url: "https://api.spotify.com/v1/shows/5coPkBhuBhna1VrjUCUocA/episodes",
              method: "get",
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              params: {
                market: "US",
              },
            })
          ).data.items;
          setData(result);

          setIsLoading(false);
        }
      };

      fetchData();
    }
  }, []);

  return (
    <Layout bodyclassName="page-services">
      <SEO
        title={
          "HowToVietnamese Podcast | Learn Southern Vietnamese with HowToVietnamese"
        }
      />

      <PromoBanner />
      <div
        className="contentContainer jumbotron jumbotron-fluid margin-from-header mt-6 mt-md-6 mb-0 pt-4 pt-md-8 pb-7 pb-md-7 bg-grey"
        id="home-banner"
      >
        <div className="container d-flex justify-content-center pl-md-0 pr-md-0 ">
          <div className="col-12 col-md-11">
            <div className="row d-flex justify-content-between">
              <div className="col-12 col-md-6 text-left flex-column d-flex justify-content-center">
                <h1 className="display-4">Podcast</h1>
                <h3 className="text-muted pt-1">
                  Brings you authentic Southern Vietnamese audio lessons to help
                  you speak Vietnamese confidently
                </h3>
                <div>
                  <button className="pl-0 align-left">
                    <OutboundLink
                      target="_blank"
                      style={podcastButton}
                      href="https://podcasts.apple.com/vn/podcast/southern-vietnamese-lessons-howtovietnamese/id1531464942"
                      className="btn"
                    >
                      <img
                        style={iconStyle}
                        src={applePodcast}
                        alt="apple podcast icon"
                      ></img>
                      Apple Podcasts
                    </OutboundLink>
                  </button>
                  <button className="pl-0 align-left">
                    <OutboundLink
                      target="_blank"
                      style={podcastButton}
                      href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9mNDFhMTY4L3BvZGNhc3QvcnNz"
                      className="btn"
                    >
                      <img
                        style={iconStyle}
                        src={googlePodcast}
                        alt="google podcast icon"
                      ></img>
                      Google Podcasts
                    </OutboundLink>
                  </button>
                  <button className="pl-0 align-left">
                    <OutboundLink
                      target="_blank"
                      style={podcastButton}
                      href="https://open.spotify.com/show/5coPkBhuBhna1VrjUCUocA"
                      className="btn"
                    >
                      <img
                        style={iconStyle}
                        src={spotify}
                        alt="spotify podcast icon"
                      ></img>
                      Spotify
                    </OutboundLink>
                  </button>
                </div>
                <div className="mt-5">
                  <button className="pl-0 align-left">
                    <Link
                      target="_blank"
                      to="/contact"
                      className="btn btn-primary"
                      style={mainButton}
                    >
                      <img
                        style={iconStyle}
                        src={chat}
                        alt="suggest episode button"
                      ></img>
                      Suggest Topic
                    </Link>
                  </button>
                  {/* <button className="pl-0 align-left">
                    <OutboundLink
                      target="_blank"
                      href="https://anchor.fm/howtovietnamese/support"
                      className="btn btn-primary"
                      style={mainButton}
                    >
                      <img
                        style={iconStyle}
                        src={dollar}
                        alt="support podcast"
                      ></img>
                      Support
                    </OutboundLink>
                  </button> */}
                </div>
              </div>

              <div className="col-md-6 hide-on-mobile">
                <img src={HomepageImage} className="homeBanner"></img>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mt-6 mt-md-8 mb-2 mb-md-3 align-center">
          <h1>Recent Episodes</h1>
        </div>
        {isLoading ? (
          <div className="row">Loading ...</div>
        ) : (
          <div className="row pb-4">
            {episodes.map((episode) => {
              return (
                <div
                  key={episode.id}
                  className="col-12 col-md-8 m-auto"
                  height="250"
                >
                  {episode.title}
                  <LazyIframe
                    key={episode.id}
                    src={`https://open.spotify.com/embed-podcast/episode/${episode.id}`}
                    height="250"
                    width="100%"
                    frameBorder="0"
                  ></LazyIframe>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="col-12 no-bg">
        <MailChimpBanner />
      </div>
    </Layout>
  );
}

export default PodcastPage;
